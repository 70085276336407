import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, HStack, Center, Pressable, Text } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import Wrapper from "@views/components/ui/wrapper";
import Table from "@views/components/ui/table";
import { useTranslation } from "react-i18next";
import TreeTransitPermission from "./components/tree_transit_permission";
import HeaderSection from "@views/components/ui/header_section";
import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";

const WorkingPlan = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box>
      <VStack>
        <Box>
          <HeaderSection imageUrl={ForestImageCover} title={"Tree Transit Permission"} />
        </Box>
        <Box my="10">
          <HStack>
            <Box w="30%" h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center">
                  <SecondarySiderMenu type="Guidelines_For_Obtaining_Tree_Felling_And_Transit_Permission" />
                </Box>
              </VStack>
            </Box>
            <Box w="65%" h="100%">
              <TreeTransitPermission />
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default WorkingPlan;
